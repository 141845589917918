var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"01dfe2d02afdecd26602a5bd1fcbcb7e1df18adb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0, // to be changed on production
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  dsn: 'https://7741a3516ee507e1119efe04852fa732@sentry.rapidcompact.link/5',
});
